.footer__text {
  color: var(--color-text);
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
  margin-top: 20vh;
  /* padding-bottom: 10rem; */
}

.wavespan {
  /* Positioning */
  position: sticky;
  bottom: 0;

  /* Sizing */
  width: 100%;
  aspect-ratio: 540 / 327;

  /* Background Image Settings */
  background-image: url('../../assets/layered-waves-haikei.svg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain; /* Changed from cover to contain */

  /* Overflow Handling */
  overflow: hidden;
}

@media screen and (max-width: 980px) {
  .footer__text {
    margin-top: 20vh;
  }
}
