
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #0d1321;
    --color-bg-variant: #1d2d44;
    --color-primary: #f0ebd8;
    --color-primary-variant: #748cab;
    --color-white: #fff;
    --color-black: #000;
    --color-light: rgba(255,255,255,0.6);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 85%;
    --container-width-sm: 95%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: var(--color-bg);
    color: var(--color-primary);
    line-height: 1.7;
    background-image: url(../src/assets/polygon-scatter-haikei.svg);
    
    /*Make background fit horizontally but scroll vertically*/
    aspect-ratio: 540/960;
    background-size: cover;
    /* background-position: center; */
}

/* ========= General Styles ========= */

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}


h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    color: var(--color-primary);
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 4rem;
    height: 80vmax;
}

section > h2, 
section > h5 {
    text-align: center;
    color : var(--color-light);
    font-size: 1rem;
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
    font-size: 2.0rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
    -webkit-transition: var(--transition);
    -moz-transition: var(--transition);
    -ms-transition: var(--transition);
    -o-transition: var(--transition);
}

a:hover {
    color: var(--color-primary-variant);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    cursor: pointer;
    border-radius: 30px;
    border: 2px solid var(--color-primary);
    transition: var(--transition);
    -webkit-transition: var(--transition);
    -moz-transition: var(--transition);
    -ms-transition: var(--transition);
    -o-transition: var(--transition);
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;

}

.btn:hover {
    background: var(--color-primary);
    color: var(--color-bg-variant);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-bg-variant);
    color: var(--color-primary);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ========= Media Queries (Medium Devices) ========= */

@media screen and (max-width: 992px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }

}
/* ========= Media Queries (Large Devices) ========= */

@media screen and (max-width: 1200px) {
    .container {
        width: var(--container-width-lg);
    }

    section {
        padding-top:2rem;
        margin-top: 6rem;
    }
    section {
        margin-top: 8rem;
        /* height: 75vh; */
    }
}
/* ========= Media Queries (Small Devices) ========= */

@media screen and (max-width: 390px) {
    .container {
        width: var(--container-width-md);
    }

    section > h2 {
        margin-bottom: 6rem;
    }
    section {
        margin-top: 4rem;
        height: 120vmax;
    }

}