.portfolio__section {
  height: auto !important;
  /* margin-top:100vh; */
}
.portfolio-item {
  display: block;
  border: 1px solid #ccc;
  width: 40vmin;
  height: 40vmin;
  text-align: center;
  margin: 0 auto;
  margin-top: 2rem;
  overflow: hidden;

  border-radius: 20px;
  display: block;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  box-shadow: 0rem 0rem 1rem rgba(29, 84, 121, 0.792);
  transition: var(--transition);
}
/*make portfolio item increase in size when hovered and decrease when not hovered*/
.portfolio-item:hover {
  transform: scale(1.1);
  transition: var(--transition);
} 

.portfolio-item a > img {
  flex-shrink: 0;
  min-width: 75%;
  min-height: 75%;
/* make sure the image is not smaller than the container */
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  display: block;
}

.portfolio__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: repeat(2, 1fr);
  gap: 1rem;
  justify-content: center;
  text-align: center;
  margin-left: 20vh;
  margin-right: 20vh;
}

@media screen and (max-width: 768px) {
  .portfolio__section {
    margin-top: 100px;
  }
}
