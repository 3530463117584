header {
  padding-top: 0rem;
  margin-top: 0rem;
  margin-bottom: 200px;
  
  height: auto;
  overflow: visible !important;
}

.wavespantop {
  /* Removed aspect-ratio to allow explicit height control */
  /* aspect-ratio: 540 /304 ; */

  width: 100% !important; /* Ensure full horizontal stretch */
  max-height: 20vh; /* Limit height to 20% of viewport height */
  height: auto; /* Allow height to adjust based on content */

  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% auto; /* Stretch horizontally, maintain aspect ratio vertically */

  position: absolute;
  top: 0;
  left: 0; /* Ensure it starts from the left edge */
  z-index: 1; /* Keep it behind other header content */

  overflow: hidden; /* Prevent overflow issues */
}
.wavestop{
  background-image: url('../../assets/layered-waves-haikei-top.svg');
}

.header__container {
  text-align: center;
  position: relative;
}

/*  ========== CTA ========== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/*  ========== SOCIALS ========== */

.header__socials {
  position: absolute;
  align-items: center;
  display: flex;
  justify-items: center;
  flex-direction: row;
  gap: 1.2rem;
  left: 50%;
  transform: translateX(-25%);
  bottom: 350px;
  top: 35rem;

  z-index: 2;
  scale: 2;
  -webkit-transform: translateX(-25%);
  -moz-transform: translateX(-25%);
  -ms-transform: translateX(-25%);
  -o-transform: translateX(-25%);
}

.header__socials > a > p {
  font-size: 0;
}

/* .header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
} */

/*  ========== PHOTO ========== */

.me {
  background: linear-gradient(var(--color-primary-variant), transparent);
  width: 20rem;
  height: 20rem;
  position: absolute;
  left: calc(50% - 10rem);
  margin-top: 2rem;
  border-radius: 12rem 12rem 0 0;
  -webkit-border-radius: 12rem 12rem 0 0;
  -moz-border-radius: 12rem 12rem 0 0;
  -ms-border-radius: 12rem 12rem 0 0;
  -o-border-radius: 12rem 12rem 0 0;
  padding: 0rem 0rem 0rem 0rem;
  -webkit-box-shadow: 0px 0px 20px 0px var(--color-primary-variant);
}

/* ========= Scroll Down ========= */

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

@media screen and (min-width: 1150px) {
  header {
    height: 400px;
  }

  .header__socials {
    top: 600px;
  }
}

@media screen and (max-width: 1149px) {
  header {
    height: 350px;
  }

  .header__socials {
    top: 650px;
  }
}

@media screen and (max-width: 700px) {
  .scroll__down {
    display: none;
  }
}
