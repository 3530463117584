@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
header {
  padding-top: 0rem;
  margin-top: 0rem;
  margin-bottom: 200px;
  
  height: auto;
  overflow: visible !important;
}

.wavespantop {
  /* Removed aspect-ratio to allow explicit height control */
  /* aspect-ratio: 540 /304 ; */

  width: 100% !important; /* Ensure full horizontal stretch */
  max-height: 20vh; /* Limit height to 20% of viewport height */
  height: auto; /* Allow height to adjust based on content */

  background-position: top;
  background-repeat: no-repeat;
  background-size: 100% auto; /* Stretch horizontally, maintain aspect ratio vertically */

  position: absolute;
  top: 0;
  left: 0; /* Ensure it starts from the left edge */
  z-index: 1; /* Keep it behind other header content */

  overflow: hidden; /* Prevent overflow issues */
}
.wavestop{
  background-image: url(/static/media/layered-waves-haikei-top.ca97878f.svg);
}

.header__container {
  text-align: center;
  position: relative;
}

/*  ========== CTA ========== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  grid-gap: 1.2rem;
  gap: 1.2rem;
  justify-content: center;
}

/*  ========== SOCIALS ========== */

.header__socials {
  position: absolute;
  align-items: center;
  display: flex;
  justify-items: center;
  flex-direction: row;
  grid-gap: 1.2rem;
  gap: 1.2rem;
  left: 50%;
  transform: translateX(-25%);
  bottom: 350px;
  top: 35rem;

  z-index: 2;
  scale: 2;
  -webkit-transform: translateX(-25%);
  -moz-transform: translateX(-25%);
  -ms-transform: translateX(-25%);
  -o-transform: translateX(-25%);
}

.header__socials > a > p {
  font-size: 0;
}

/* .header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
} */

/*  ========== PHOTO ========== */

.me {
  background: linear-gradient(var(--color-primary-variant), transparent);
  width: 20rem;
  height: 20rem;
  position: absolute;
  left: calc(50% - 10rem);
  margin-top: 2rem;
  border-radius: 12rem 12rem 0 0;
  -webkit-border-radius: 12rem 12rem 0 0;
  -moz-border-radius: 12rem 12rem 0 0;
  -ms-border-radius: 12rem 12rem 0 0;
  -o-border-radius: 12rem 12rem 0 0;
  padding: 0rem 0rem 0rem 0rem;
  -webkit-box-shadow: 0px 0px 20px 0px var(--color-primary-variant);
}

/* ========= Scroll Down ========= */

.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

@media screen and (min-width: 1150px) {
  header {
    height: 400px;
  }

  .header__socials {
    top: 600px;
  }
}

@media screen and (max-width: 1149px) {
  header {
    height: 350px;
  }

  .header__socials {
    top: 650px;
  }
}

@media screen and (max-width: 700px) {
  .scroll__down {
    display: none;
  }
}

#experience {
  height: 100vh;
  margin-top: 200px;
}
.experience {
  /* height: 20vh; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0rem;
  gap: 0rem;
  text-align: center;
}

.experience__container > div {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
  text-align: center;
  background: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience__container > div:hover {
  background: var(--color-bg);
  border-color: var(--color-primary-variant);
  cursor: default;
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary-variant);
}

.experience__content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
  text-align: center;
}

.experience_details {
  display: grid;
  text-align: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.experience__details-icon {
  margin-top: 5px;
  color: var(--color-primary-variant);
}

.experience__container {
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
  text-align: center;
}
.experience__container > div {
  padding: 2rem 1rem;
  text-align: center;
  margin: 0 auto;
  width: 100%;
}
.experience__content {
  padding: 1rem;
  text-align: center;
}

@media screen and (min-width: 1150px) {
  #experience {
    margin-top: 100px;
    height: auto;
  }
}
@media screen and (max-width: 1149px) {
  #experience {
    margin-top: 100px;
    height: auto;
  }
}
@media screen and (max-width: 610px) {
  #experience {
    margin-top: 100px;
    height: auto;
  }
}

.footer__text {
  color: var(--color-text);
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;
  margin-top: 20vh;
  /* padding-bottom: 10rem; */
}

.wavespan {
  /* Positioning */
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;

  /* Sizing */
  width: 100%;
  aspect-ratio: 540 / 327;

  /* Background Image Settings */
  background-image: url(/static/media/layered-waves-haikei.b6dd0514.svg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain; /* Changed from cover to contain */

  /* Overflow Handling */
  overflow: hidden;
}

@media screen and (max-width: 980px) {
  .footer__text {
    margin-top: 20vh;
  }
}

.row {

  padding-top: 2rem;
  padding-right: 2rem;
  padding-left: 2rem;
  /* padding-bottom: 2rem; */
  background-color: var(--color-bg-variant);
  border: 1px solid #ccc;
  height: 220px;
  border-radius: 40px;
  background: transparent;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  display: grid;
  grid-auto-columns: 1fr 1fr;
  grid-template: 1fr 1fr / 1fr 1fr;
  grid-row-gap: 1rem;
  row-gap: 1rem;
  text-align: center;
  box-sizing: content-box;
}

.contact__container {
/* background-image: url(../../assets/blob-scene-haikei.svg); */
background-repeat: no-repeat;
background-size: cover;
background-position: center;
border-radius: 40px;

}
.btn {
  background: var(--color-bg);
}
.btn:hover {
  background: var(--color-primary);
  color: var(--color-bg-variant);
  border-color: transparent;
}
.btn > p {
  font-size: 0;
}

@media screen and (min-width: 1150px) {
  #contact {
    margin-top: 200px;
    /* padding-bottom: 3rem; */
    height: auto;
  }
  .row {
    height: 300px;
  }
}

@media screen and (max-width: 768px) {
  .row {
    grid-template: 0.5fr 0.5fr 0.5fr 0.5fr / 1fr;
    height: 400px;
  }
  #contact {
    margin-top: 100px;
    height: auto;
    /* margin-bottom: 7rem; */
  }
}

#about {
  /* padding-top: 400px; */
}
.about__container {
  display: grid;
  grid-template-columns: 35% 50%;
  grid-gap: 15%;
  gap: 15%;
  height: auto;
}

.about__me {
  max-width: 30vmin;
  max-height: 30vmin;
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
  background: linear-gradient(
    -45deg,
    transparent,
    var(--color-bg-variant),
    transparent
  );
  display: grid;
  place-items: center;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(40, 49, 57, 0.75);
}

.about__me-image {
  max-width: 30vmin;
  max-height: 30vmin;
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
  overflow: hidden;
  transform: rotate(-15deg);
  -webkit-transform: rotate(-15deg);
  -moz-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
  -webkit-box-shadow: -5px 3px 5px 0px rgba(39, 48, 56, 0.75);
}

.about__me-image:hover {
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.about__card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  padding: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  transition: var(--transition);
  -webkit-transition: var(--transition);
  -moz-transition: var(--transition);
  -ms-transition: var(--transition);
  -o-transition: var(--transition);
}

.about__card:hover {
  background: var(--color-bg);
  border-color: var(--color-primary-variant);
  cursor: default;
}

.about_icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.about__card h5 {
  font-size: 0.95rem;
  color: var(--color-primary-variant);
}

.about_card small {
  font-size: 0.7rem;
  color: var(--color-light);
}

.about_content p {
  margin: 2rem 2rem 2rem;
  color: var(--color-light);
}

/* ========= Media Queries (Medium Devices) ========= */

@media screen and (min-width: 601px) {
  #about {
    margin-top: 400px;
    height: auto;
  }
  .about__container {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    gap: 0px;
  }
  .about__me {
    width: 50%;
    margin: 2rem auto 4rem;
  }
  .about__content p {
    margin: 1rem 0 1.4rem;
  }
}

/* ========= Media Queries (Small Devices) ========= */

@media screen and (max-width: 600px) {
  #about {
    height: auto;
    margin-top: 600px;
  }
  .about__container {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    gap: 0px;
  }
  .about__cards {
    grid-template-columns: 1fr;
    margin-bottom: 40px;
  }
  .about__me {
    width: 100%;
    margin: 1rem auto 4rem;
  }
  .about__content p {
    margin: 1rem 0 1.4rem;
    text-align: center;
  }
}

.portfolio__section {
  height: auto !important;
  /* margin-top:100vh; */
}
.portfolio-item {
  display: block;
  border: 1px solid #ccc;
  width: 40vmin;
  height: 40vmin;
  text-align: center;
  margin: 0 auto;
  margin-top: 2rem;
  overflow: hidden;

  border-radius: 20px;
  display: block;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  box-shadow: 0rem 0rem 1rem rgba(29, 84, 121, 0.792);
  transition: var(--transition);
}
/*make portfolio item increase in size when hovered and decrease when not hovered*/
.portfolio-item:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  transition: var(--transition);
} 

.portfolio-item a > img {
  flex-shrink: 0;
  min-width: 75%;
  min-height: 75%;
/* make sure the image is not smaller than the container */
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  display: block;
}

.portfolio__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: repeat(2, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
  text-align: center;
  margin-left: 20vh;
  margin-right: 20vh;
}

@media screen and (max-width: 768px) {
  .portfolio__section {
    margin-top: 100px;
  }
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg: #0d1321;
    --color-bg-variant: #1d2d44;
    --color-primary: #f0ebd8;
    --color-primary-variant: #748cab;
    --color-white: #fff;
    --color-black: #000;
    --color-light: rgba(255,255,255,0.6);

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 85%;
    --container-width-sm: 95%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    background: #0d1321;
    background: var(--color-bg);
    color: #f0ebd8;
    color: var(--color-primary);
    line-height: 1.7;
    background-image: url(/static/media/polygon-scatter-haikei.20ea2db7.svg);
    
    /*Make background fit horizontally but scroll vertically*/
    aspect-ratio: 540/960;
    background-size: cover;
    /* background-position: center; */
}

/* ========= General Styles ========= */

.container {
    width: 75%;
    width: var(--container-width-lg);
    margin: 0 auto;
}


h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    color: #f0ebd8;
    color: var(--color-primary);
}

h1 {
    font-size: 2.5rem;
}

section {
    margin-top: 4rem;
    height: 80vmax;
}

section > h2, 
section > h5 {
    text-align: center;
    color : rgba(255,255,255,0.6);
    color : var(--color-light);
    font-size: 1rem;
}

section > h2 {
    color: #f0ebd8;
    color: var(--color-primary);
    margin-bottom: 3rem;
    font-size: 2.0rem;
}

.text-light {
    color: rgba(255,255,255,0.6);
    color: var(--color-light);
}

a {
    color: #f0ebd8;
    color: var(--color-primary);
    transition: all 400ms ease;
    transition: var(--transition);
    -webkit-transition: all 400ms ease;
    -webkit-transition: var(--transition);
    -moz-transition: all 400ms ease;
    -moz-transition: var(--transition);
    -ms-transition: all 400ms ease;
    -ms-transition: var(--transition);
    -o-transition: all 400ms ease;
    -o-transition: var(--transition);
}

a:hover {
    color: #748cab;
    color: var(--color-primary-variant);
}

.btn {
    width: -webkit-max-content;
    width: max-content;
    display: inline-block;
    color: #f0ebd8;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    cursor: pointer;
    border-radius: 30px;
    border: 2px solid #f0ebd8;
    border: 2px solid var(--color-primary);
    transition: all 400ms ease;
    transition: var(--transition);
    -webkit-transition: all 400ms ease;
    -webkit-transition: var(--transition);
    -moz-transition: all 400ms ease;
    -moz-transition: var(--transition);
    -ms-transition: all 400ms ease;
    -ms-transition: var(--transition);
    -o-transition: all 400ms ease;
    -o-transition: var(--transition);
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;

}

.btn:hover {
    background: #f0ebd8;
    background: var(--color-primary);
    color: #1d2d44;
    color: var(--color-bg-variant);
    border-color: transparent;
}

.btn-primary {
    background: #1d2d44;
    background: var(--color-bg-variant);
    color: #f0ebd8;
    color: var(--color-primary);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ========= Media Queries (Medium Devices) ========= */

@media screen and (max-width: 992px) {
    .container {
        width: 85%;
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }

}
/* ========= Media Queries (Large Devices) ========= */

@media screen and (max-width: 1200px) {
    .container {
        width: 75%;
        width: var(--container-width-lg);
    }

    section {
        padding-top:2rem;
        margin-top: 6rem;
    }
    section {
        margin-top: 8rem;
        /* height: 75vh; */
    }
}
/* ========= Media Queries (Small Devices) ========= */

@media screen and (max-width: 390px) {
    .container {
        width: 85%;
        width: var(--container-width-md);
    }

    section > h2 {
        margin-bottom: 6rem;
    }
    section {
        margin-top: 4rem;
        height: 120vmax;
    }

}
