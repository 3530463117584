#experience {
  height: 100vh;
  margin-top: 200px;
}
.experience {
  /* height: 20vh; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0rem;
  text-align: center;
}

.experience__container > div {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  text-align: center;
  background: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experience__container > div:hover {
  background: var(--color-bg);
  border-color: var(--color-primary-variant);
  cursor: default;
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary-variant);
}

.experience__content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  text-align: center;
}

.experience_details {
  display: grid;
  text-align: center;
  gap: 1rem;
}

.experience__details-icon {
  margin-top: 5px;
  color: var(--color-primary-variant);
}

.experience__container {
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  text-align: center;
}
.experience__container > div {
  padding: 2rem 1rem;
  text-align: center;
  margin: 0 auto;
  width: 100%;
}
.experience__content {
  padding: 1rem;
  text-align: center;
}

@media screen and (min-width: 1150px) {
  #experience {
    margin-top: 100px;
    height: auto;
  }
}
@media screen and (max-width: 1149px) {
  #experience {
    margin-top: 100px;
    height: auto;
  }
}
@media screen and (max-width: 610px) {
  #experience {
    margin-top: 100px;
    height: auto;
  }
}
